import React from 'react'
import { Link } from 'gatsby'
import EmbedVideo from '../../EmbedVideo'
// import { graphql, useStaticQuery } from 'gatsby'
// import { GatsbyImage } from 'gatsby-plugin-image'

export default function AboutTheFestival(props) {
	return (
		<section className="pt-4 pb-8 md:p-8 md:pb-12 bg-gradient-to-br from-bbf-pink to-pink-500 md:flex md:items-start">
			<div className={`md:w-1/2 p-4`}>
				<h2 className={`text-4xl font-heading font-bold text-white mb-8`}>About the Boswell Book Festival</h2>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 pb-4 border-l-8 border-bbf-pink`}>The world’s only festival of biography and memoir, normally set in the spectacular grounds of Dumfries House, draws an enthusiastic audience attracted by its unique theme.</p>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 pb-4 border-l-8 border-bbf-pink`}>Inspired by the great Ayrshire biographer James Boswell of Auchinleck, at the heart of the Festival is a programme of stories taken from the inspirational lives of people past and present - told through talks, drama, art and music.</p>
				<p className={`lg:text-lg xl:text-xl 2xl:text-2xl text-white leading-relaxed px-4 border-l-8 border-bbf-pink`}>
					<Link to={`/about`} className={`text-bbf-yellow border-b-2 border-transparent hover:border-bbf-yellow transition-all`}>Find out more and view highlights</Link>
				</p>
			</div>
			<div className={`md:w-1/2 p-4`}>
				{/*<TwitterFeed />*/}
				{/* note: video won't display in a flex container */}
				<EmbedVideo src={`https://www.youtube-nocookie.com/embed/38qMq-FR9xg`} title={`2024 Festival`} />
			</div>
		</section>
	)
}
